@use "sass:math";
$maxW: 1696px;
$maxWContent: 1456px;
$maxWRem: 185.4rem;
$mobileW: 900px;
@function vw($value) {
    $res: math.div($value * 100, 1845) + vw;
    @return $res;
}

@import url('https://fonts.cdnfonts.com/css/circe');

*,
*::before,
*::after {
  box-sizing: border-box;
}
html,body{
	margin: 0;
  padding: 0;
  width: 100%;
	font-size: 10px;
	color: #000;
	font-family: 'Circe', sans-serif;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}

#root{
	width: 100%;
	display: flex;
	flex-direction: column;
  justify-content: flex-start;
	align-items: center;
	position: relative;
  >.App{
		max-width: $maxW;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
    padding: 0 2.8rem;
    .popup{
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.67);
      transform: scale(0);
      opacity: 0;
      transition: opacity ease-in-out 0.3s 0s;
      &.is-on{
        transform: scale(1);
        opacity: 1;
      }
      >div{
        background: #fff;
        width: 90%;
        max-width: 808px;
        color: #000;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        padding: 6rem;
        position: relative;
        >.close{
          position: absolute;
          right: 2.4rem;
          top: 2.4rem;
          width: 24px;
          height: 24px;
          cursor: pointer;
          &:after{
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            left: 12px;
            top: 0;
            background: #000;
            transform: rotate(-45deg);
          }
          &:before{
            content: "";
            position: absolute;
            width: 2px;
            height: 100%;
            left: 12px;
            top: 0;
            background: #000;
            transform: rotate(45deg);
          }
        }
      }
    }
    .hidden{
      display: none !important;
    }
    .banner{
      margin-top: 3.5rem;
      margin-bottom: 4rem;
      >img{
        width: 100%;
      }
    }
    .header_ttl{
      color: #0C0C0C;
      font-size: 4rem;
      font-style: normal;
      font-weight: 700;
      line-height: 4.5rem;
    }
    .downloadPDF{
      text-decoration: none;
      &.mobile{
        display: none;
      }
      >div{
        position: relative;
        border: 1px solid #DCDCE5;
        background: #fff;
        padding: 4rem 2.4rem;
        >div{
          &:nth-child(1){
            color: #707075;
            font-size: 1.6rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
          }
          &:nth-child(2){
            color: #000;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 700;
            line-height: 2.4rem;
            margin-top: 1.6rem;
          }
        }
      }
    }
    .calc_area{
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 4rem;
      >div{
        &:nth-child(1){
          width: 66%;
          >div{
            padding: 3.2rem 2.4rem;
            width: 100%;
            background: #F0F0F5;
            >div{
              &:nth-child(1){
                color: #0C0C0C;
                font-size: 2.6rem;
                font-style: normal;
                font-weight: 700;
                line-height: 3.6rem;
                >b{
                  color: #358502;
                }
              }
              &:nth-child(2),&:nth-child(4){
                margin-top: 2.4rem;
                margin-bottom: 1.6rem;
                color: #000;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.8rem;
              }
              &:nth-child(3){
                display: flex;
                justify-content: flex-start;
                .container {
                  display: flex;
                  align-items: center;
                  position: relative;
                  cursor: pointer;
                  font-size: 2.2rem;
                  user-select: none;
                  &:nth-child(1){
                    margin-right: 4rem;
                  }
                  input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                  }
                  .checkmark {
                    position: relative;
                    height: 2.4rem;
                    width: 2.4rem;
                    border: 0.2rem solid #000;
                    margin-right: 0.8rem;
                    &:after{
                      content: "";
                      position: absolute;
                      width: 1.2rem;
                      height: 1.2rem;
                      background: #000;
                      left: 50%;
                      top: 50%;
                      transform: translate(-50%,-50%) scale(1.6);
                      opacity: 0;
                      transition: all ease-in-out 0.2s 0s;
                    }
                  }
                  &.red{
                    color: #E22045;
                    .checkmark {
                      border: 0.2rem solid #E22045;
                    }
                  }
                  input:checked ~ .checkmark {
                    &:after{
                      opacity: 1;
                      transform: translate(-50%,-50%) scale(1);
                    }
                  }
                }
              }
              &:nth-child(5){
                display: flex;
                flex-direction: flex-start;
                >div{
                  &:nth-child(1){
                    margin-right: 3.3rem;
                    >input{
                      width: 11.4rem;
                      height: 5.2rem;
                      border: 0.2rem solid #0C0C0C;
                      background: #fff;
                      text-align: center;
                      color: #707075;
                      font-size: 1.8rem;
                      font-style: normal;
                      font-weight: 300;
                      line-height: 2.4rem;
                      &.red{
                        border: 0.2rem solid #E22045;
                      }
                    }
                  }
                  &:nth-child(2){
                    >button{
                      background: #000;
                      width: 23.9rem;
                      height: 5.2rem;
                      border: 0;
                      color: #FFF;
                      text-align: center;
                      font-size: 2.6rem;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 3.6rem;
                      cursor: pointer;
                    }
                  }
                }
              }
              &#message{
                padding: 2.4rem 0;
                >div{
                  color: #000;
                  font-size: 1.8rem;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 2.4rem;
                  >b{
                    color: #4A56A0;
                  }
                  &.err{
                    color: #E22045;
                  }
                }
              }
              &.sqrs{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                >div{
                  width: 49%;
                  height: 24.4rem;
                  background: #E0EBD9;
                  padding: 2rem 2rem 1.6rem 2rem;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-start;
                  align-items: flex-start;
                  position: relative;
                  &:nth-child(1), &:nth-child(2){
                    margin-bottom: 2rem;
                  }
                  >.ttl{
                    color: #358502;
                    font-size: 2rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 2.8rem;
                  }
                  >.descritiomn{
                    color: #000;
                    font-size: 1.6rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 2rem;
                    margin-top: 1.6rem;
                  }
                  >.value{
                    position: absolute;
                    right: 2rem;
                    bottom: 1.6rem;
                    >span{
                      &:nth-child(1){
                        color: #509D1E;
                        text-align: right;
                        font-size: 4rem;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 4rem;
                        letter-spacing: 2px;
                        text-transform: uppercase;
                      }
                      &:nth-child(2){
                        color: #000;
                        font-size: 1.8rem;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 2.8rem;
                      }
                    }
                  }
                }
              }
            }
          }
          >.bot_legend_1{
            display: block;
            color: #000;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.4rem;
            margin-top: 3.2rem;
          }
          >.bot_legend_2{
            color: #000;
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 300;
            line-height: 2.8rem;
            display: flex;
            margin-top: 2.4rem;
             margin-bottom: 4rem;
            b{
              font-weight: 400;
            }
            >img{
              width: 7.9rem;
              margin-right: 2.1rem;
            }
          }
          >.links{
            >div:nth-child(1){
              color: #000;
              font-size: 2.4rem;
              font-style: normal;
              font-weight: 700;
              line-height: 2.7rem;
            }
            >.r{
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              >span{
                &:nth-child(1){
                  width: 2.4rem;
                  color: #000;
                  text-align: right;
                  font-size: 1.8rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 2.4rem;
                  margin-right: 1.6rem;
                  margin-bottom: 2.3rem;
                }
                &:nth-child(2){
                  color: #000;
                  font-size: 1.8rem;
                  font-style: normal;
                  font-weight: 350;
                  line-height: 2.4rem;
                }
              }
            }
          }
        }
        &:nth-child(2){
          width: 32%;
          .downloadPDF > div{
            margin-bottom: 4rem;
          }
          .rightVals{
            background: #F0F0F5;
            padding: 3.2rem 2.4rem;
            >div{
              &:nth-child(1){
                color: #000;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.8rem;
                display: flex;
                >img{
                  margin-left: 1.2rem;
                  width: 2.4rem;
                  cursor: pointer;
                }
              }
              &:nth-child(2){
                color: #000;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 400;
                line-height: 2rem;
                margin-top: 1rem;
                margin-bottom: 2.4rem;
              }
            }
            >span{
              display: flex;
              >div{
                &:nth-child(1){
                  width: 15rem;
                  background: #509D1E;
                  color: #FFF;
                  font-size: 2.2rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 2.4rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  height: 5.6rem;
                }
                &:nth-child(2){
                  height: 5.6rem;
                  background: #fff;
                  flex-shrink: 0;
                  flex-grow: 1;
                  display: flex;
                  align-items: center;
                  justify-content: flex-end;
                  padding-right: 3.2rem;
                  >span{
                    &:nth-child(1){
                      color: #509D1E;
                      text-align: right;
                      font-size: 2.3rem;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 2.8rem;
                    }
                    &:nth-child(2){
                      color: #030303;
                      font-size: 1.8rem;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 2.8rem;
                    }
                  }
                }
              }
            }
            .legend{
              position: relative;
              color: #000;
              font-size: 1.8rem;
              font-style: normal;
              font-weight: 400;
              line-height: 2.4rem;
              margin-top: 1.2rem;
              margin-bottom: 3.2rem;
              >span{
                color: #4F4F4F;
                font-size: 1.3rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; 
                display: block;
              }
            }
            >ul{
              margin: 0;
              padding: 0;
              list-style: none;
              >li{
                color: #4F4F4F;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem;
                &:nth-child(1){
                  margin-bottom: 2.4rem;
                }
              }
            }
          }
          .shorts{
            margin-top: 4rem;
            >div{
              &:nth-child(1){
                color: #000;
                font-size: 2.4rem;
                font-style: normal;
                font-weight: 700;
                line-height: 2.7rem;
                margin-bottom: 1.8rem;
              }
              &:nth-child(2){
                color: #4F4F4F;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2.4rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: $maxW){
	html,body{
		font-size: vw(10);
	}
}

@media (max-width: $mobileW){
	html,body{
		font-size: 6px;
	}
  #root{
    >.App{
      .downloadPDF{
        &.desctop{
          display: none;
        }
        &.mobile{
          display: block;
          width: 100%;
          >div{
            width: 100%;
            margin-bottom: 4rem;
          }
        }
      }
      >.calc_area{
        flex-direction: column;
        >div{
          width: 100% !important;
          &:nth-child(1){
            >.bot_legend_2{
              flex-direction: column;
              >img{
                align-self: center;
              }
            }
          }
        }
      }
    }
  }
}
